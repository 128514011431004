import { useEffect, useState } from 'react';
import { telegramWebAppLogin } from '../services/api';

export const useIsTelegram = () => {
  const [isTelegram, setIsTelegram] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    if (window?.location?.hash) {
      const { hash } = window.location;
      if (hash.includes('tgWebAppData')) {
        window.localStorage.setItem('webapp_init_data', hash);
        const tgWebAppData = new URLSearchParams(hash.substring(1)).get('tgWebAppData');
        window.localStorage.setItem('tgWebAppData', tgWebAppData);
        const user = new URLSearchParams(tgWebAppData).get('user');
        window.localStorage.setItem('user', user);
        const sendLogin = async (tgWebAppData) => {
          const response = await telegramWebAppLogin(tgWebAppData);
          if (response?.is_new) {
            setShowOnboarding(true);
          }
        };
        sendLogin(tgWebAppData);
        setIsTelegram(true);
      } else if (
        window.localStorage.getItem('webapp_init_data') &&
        window.localStorage.getItem('user')
      ) {
        setIsTelegram(true);
      }
    }
  }, []);

  return { isTelegram, showOnboarding };
};
