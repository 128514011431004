import React from 'react';
import { useTranslation } from 'react-i18next';

const WelcomeRenderer = ({ story, action, isPaused, config }) => {
	const { t } = useTranslation();
	return (
        <div className="story-component welcome">
            <h1>{t('welcome')}</h1>
            <br/>
            <h2 style={{color:'var(--color_orange)'}}>{t('analyzeUsernames')}</h2>
            <br/><br/>
            <p>
                {t('aiCategorizes')}
            </p>
            <br/>
            <div style={{textAlign: "center"}}>
                <img width="65%" src="/robot.gif" alt="AI robot" />
            </div>
        </div>
    );
};

export const renderer = WelcomeRenderer;

export const tester = (story) => {
	return {
		condition: story.type === 'Welcome',
		priority: 1,
	};
};