import axios from 'axios';

const API_BASE_URL = 'https://api.usernames.center/';
const RETRY_COUNT = 10;
const RETRY_DELAY = 1000;

const api = axios.create({
  baseURL: API_BASE_URL,
});

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const retryRequest = async (fn, ...args) => {
  let attempts = 0;
  while (attempts < RETRY_COUNT) {
    try {
      return await fn(...args);
    } catch (error) {
      attempts += 1;
      if (attempts >= RETRY_COUNT) {
        console.error('Max retry attempts reached');
        throw error;
      }
      console.warn(`Retrying request... Attempt ${attempts + 1}`);
      await delay(RETRY_DELAY);
    }
  }
};

// Fetch categories
export const fetchCategories = async (search = '') => {
  return retryRequest(async () => {
    const response = await api.get('/', {
      params: {
        q: 'get_usernames_categories',
        search,
      },
    });
    return Array.isArray(response.data) ? response.data : [];
  });
};

// Fetch usernames
export const fetchUsernames = async (isTelegram, loginData, category, search = '', pageNum = 1) => {
  return retryRequest(async () => {
    let authType = null;
    let authData = null;
    if (isTelegram) {
      authType = 'webapp';
      authData = window.localStorage.getItem('tgWebAppData');
    }
    if (loginData) {
      authType = 'widget';
      authData = JSON.stringify(loginData);
    }
    const response = await api.get('/', {
      params: {
        q: 'get_usernames_category',
        cat: category,
        p: pageNum,
        search,
        auth_type: authType,
        auth_data: authData,
      },
    });
    return typeof response.data === 'object' ? response.data : [];
  });
};

// Send Telegram login data
export const telegramLogin = async (data) => {
  return retryRequest(async () => {
    const response = await api.post('/?q=telegram_login', data);
    return response.data;
  });
};

// Send Telegram webapp login data
export const telegramWebAppLogin = async (data) => {
  return retryRequest(async () => {
    const response = await api.post('/?q=telegram_webapp_login', { data });
    return response.data;
  });
};

// Create invoice link
export const createInvoiceLink = async (subscriptionType, initData) => {
  return retryRequest(async () => {
    const response = await api.post('/invoice_link', {
      type: subscriptionType,
      initData,
    });
    return typeof response.data === 'object' ? response.data : [];
  });
};

// Fetch bookmarks
export const fetchBookmarks = async (userId) => {
  return retryRequest(async () => {
    const response = await api.get(`/bookmarks/${userId}`);
    return response.data || [];
  });
};

// Add a bookmark
export const addBookmark = async (userId, username) => {
  return retryRequest(async () => {
    const response = await api.post(`/bookmarks/${userId}`, { username });
    return response.data;
  });
};

// Remove a bookmark
export const removeBookmark = async (userId, username) => {
  return retryRequest(async () => {
    const response = await api.delete(`/bookmarks/${userId}/${username}`);
    return response.data;
  });
};
