import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { BookmarkContext } from '../contexts/BookmarkContext';
import { fetchCategories, fetchUsernames } from '../services/api';
import Navbar from '../components/Navbar';
import { useIsTelegram } from '../utils/useIsTelegram';
import { useTelegramLoginData } from '../utils/useTelegramLoginData';
import { Table, Pagination, Badge, Loader, Overlay } from '@mantine/core';
import { IconArrowLeft, IconArrowRight, IconGripHorizontal } from '@tabler/icons-react';
import Stories from 'react-insta-stories';
import * as Welcome from '../components/stories/Welcome';
import * as FreeViewing from '../components/stories/FreeViewing';
import * as SubscriptionOptions from '../components/stories/SubscriptionOptions';
import { ReactComponent as IconAI } from '../assets/IconAI.svg';
import { ReactComponent as IconJoin } from '../assets/IconJoin.svg';
import { ReactComponent as IconFeatures } from '../assets/IconFeatures.svg';
import { useTranslation } from 'react-i18next';

const MainPage = () => {
  const { t, i18n } = useTranslation();
  const loginData = useTelegramLoginData();
  const { isTelegram, showOnboarding } = useIsTelegram();
  const { authUser } = useContext(AuthContext);
  const { bookmarks, addToBookmarks, removeFromBookmarks } = useContext(BookmarkContext);
  const [isLoadingList, setLoadingList] = useState(true);
  const [isLoadingTabs, setLoadingTabs] = useState(true);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [needBuy, setNeedBuy] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);

  const navigate = useNavigate();
  const { category: routeCategory = 'All', page: routePage = '1' } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const getSearchQuery = () => {
    return searchParams.get('q') || '';
  };

  useEffect(() => {
    if (categories.length !== 0) {
      fetchUsernames(isTelegram, loginData, categories[activeTab].category, getSearchQuery().length >= 2 ? getSearchQuery() : '', page);
    }
    if (tabsRef.current) {
      const container = tabsRef.current;
      const activeTabElement = container.children[activeTab];
      if (activeTabElement) {
        container.scrollTo({
          left: activeTabElement.offsetLeft - (container.clientWidth / 4) + (activeTabElement.clientWidth / 2),
          behavior: 'smooth'
        });
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (showOnboarding) {
      setOverlayVisible(true);
      window.ym(98682253, 'reachGoal', 'openFeaturesOnboarding');
    }
  }, [showOnboarding])

  useEffect(() => {
    const search = getSearchQuery();
    const category = routeCategory;
    const pageNum = parseInt(routePage, 10) || 1;
    setPage(pageNum);
    fetchCategoriesData(search, pageNum, category);
  }, [routeCategory, routePage, location.search]);

  const fetchCategoriesData = async (search = '', pageNum = 1, category = '') => {
    try {
      setLoadingTabs(true);
      setLoadingList(true);
      setCategories([]);
      setActiveTab(0);
      const categoriesData = await fetchCategories(search);
      setCategories(categoriesData);
      if (category) {
        const index = categoriesData.findIndex((el) => el.category === category);
        if (index !== -1) {
          setActiveTab(index);
        }
      }
      setLoadingTabs(false);
      if (!categoriesData.length) {
        setUsernames([]);
        setLoadingList(false);
        return;
      }
      const selectedCategory = categoriesData[activeTab]?.category || categoriesData[0].category;
      fetchUsernamesData(selectedCategory, search, pageNum);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchUsernamesData = async (category, search = '', pageNum = 1) => {
    try {
      setNeedBuy(false);
      setLoadingList(true);
      setUsernames([]);
      const usernamesData = await fetchUsernames(isTelegram, loginData, category, search, pageNum);
      if (usernamesData.need_buy) {
        setNeedBuy(true);
      } else {
        setUsernames(usernamesData);
      }
      setLoadingList(false);
    } catch (error) {
      console.error('Error fetching usernames:', error);
    }
  };

  const handleClickTab = (index) => {
    setActiveTab(index);
    setPage(1);
    const category = categories[index].category;
    window.ym(98682253, 'reachGoal', 'openCategory', { category, search: location.search });
    window.history.pushState({}, '', `/${category}${location.search}`);
  };

  const openUsernameOnPlatform = (e, url) => {
    e.preventDefault();
    window.ym(98682253, 'reachGoal', 'openUsername', { url });
    if (window.Telegram?.WebApp) {
      try {
        window.Telegram.WebApp.openLink(url);
      } catch (error) {
        window.open(url, '_blank');
      }
    } else {
      window.open(url, '_blank');
    }
  }

  const openTelegramLink = (e, url) => {
    e.preventDefault();
    window.ym(98682253, 'reachGoal', 'openTelegramChannel', { url });
    if (window.Telegram?.WebApp) {
      try {
        window.Telegram.WebApp.openTelegramLink(url);
      } catch (error) {
        window.open(url, '_blank');
      }
    } else {
      window.open(url, '_blank');
    }
  }

  useEffect(() => {
    // This effect will run whenever the location changes
    const handlePopState = () => {
      const category = location.pathname.split('/')[1];
      navigate(`/${category}${location.search}`);
    };

    // Listen for browser back/forward actions
    window.addEventListener('popstate', handlePopState);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [location]);

  const changePage = (pageNum) => {
    setPage(pageNum);
    const category = categories[activeTab]?.category || 'All';
    window.ym(98682253, 'reachGoal', 'openCategoryPage', { category, page: pageNum, search: location.search });
    navigate(`/${category}/${pageNum}${location.search}`);
  };

  const submitSearch = (e) => {
    const value = e.target.value.trim().toLowerCase();
    const params = new URLSearchParams(location.search);
    if (value.length < 2) {
      if (params.get('q')) {
        params.delete('q');
        navigate(`${location.pathname}?${params.toString()}`);
        fetchCategoriesData();
      }
      return;
    }
    params.set('q', value);
    navigate(`${location.pathname}?${params.toString()}`);
    fetchCategoriesData(value);
  };

  useEffect(() => {
    if (categories.length > 0) {
      const selectedCategory = categories[activeTab]?.category;
      const search = getSearchQuery();
      fetchUsernamesData(selectedCategory, search, page);
    }
  }, [activeTab]);

  // Determine the join channel link based on the current language
  const joinChannelLink = i18n.language === 'ru' ? 'https://t.me/usernames_center_ru' : 'https://t.me/usernames_center';

  return (
    <div className={isTelegram && authUser ? 'authorized' : ''}>
      <Navbar
        isTelegram={isTelegram}
        authUser={authUser}
        submitSearch={submitSearch}
        getSearchQuery={getSearchQuery}
      />
      <div className="main-slug">
        <span>{t('exploreUsernames')}</span>
        <br />
        <span>
          {t('builtForTelegram')}
        </span>
      </div>
      <div className="buttons">
        <a target="_blank" rel="noreferrer" href={joinChannelLink} onClick={(e) => openTelegramLink(e, joinChannelLink)}>
          <button className="button-item">
            <IconJoin />
            <span>{t('joinChannel')}</span>
          </button>
        </a>
        <button className="button-item" onClick={() => {
            setOverlayVisible(true);
            window.ym(98682253, 'reachGoal', 'openFeatures');
        }}>
          <IconFeatures />
          <span>{t('features')}</span>
        </button>
      </div>
      {isLoadingTabs ? (
        <div className="loader" style={{ marginTop: 0 }}>
          <Loader size={64} />
        </div>
      ) : (
        <>
          <div className="tabs" ref={tabsRef}>
            {categories.map((tab, index) => (
              <div
                key={index}
                className={`tab-item ${activeTab === index ? 'active' : ''}`}
                onClick={() => handleClickTab(index)}
              >
                {tab.category.replace(/-/g, ': ').replace(/_/g, ' ')}
                <Badge color="var(--highlight-default)" size="auto">
                  {tab.count >= 1000 ? `${Math.floor(tab.count / 1000)}k` : tab.count}
                </Badge>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="usernames-list">
        <div className="table" key={categories[activeTab]?.category + page}>
          <Table className='table-usernames' verticalSpacing="lg" horizontalSpacing="lg" striped withColumnBorders>
            <Table.Thead>
              <Table.Tr className='show-desktop'>
                <Table.Th>{t('username')}</Table.Th>
                <Table.Th>{t('auctionPrice')}</Table.Th>
                <Table.Th>
                  <div className='d-flex-align-center'>
                    {t('approxPrice')} <IconAI />
                  </div>
                </Table.Th>
                <Table.Th>
                  <div className='d-flex-align-center'>
                    {t('relevanceScore')} <IconAI />
                  </div>
                </Table.Th>
                <Table.Th>
                  <div className='d-flex-align-center'>
                    {t('popularityScore')} <IconAI />
                  </div>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            {isLoadingList ? (
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td colSpan={7}>
                    <div className="loader">
                      <Loader size={64} />
                    </div>
                  </Table.Td>
                </Table.Tr>
              </Table.Tbody>
            ) : (
              <Table.Tbody>
                {(usernames || []).map((element) => (
                  <Table.Tr key={categories[activeTab]?.category + page + element.username}>
                    <Table.Td colSpan={3} className='show-mobile'>
                      <Table verticalSpacing="md" horizontalSpacing="md" withColumnBorders>
                        <Table.Tbody>
                          <Table.Tr key={`${categories[activeTab]?.category+page+element.username}-1`}>
                            <Table.Td colSpan={2} className='mobile-username-cell color-accent' onClick={(e) => openUsernameOnPlatform(e, `https://fragment.com/username/${element.username}`)}>
                              <a target="_blank" rel="noreferrer" href={`https://fragment.com/username/${element.username}`}>
                                @{element.username}
                              </a>
                            </Table.Td>
                          </Table.Tr>
                          <Table.Tr key={`${categories[activeTab]?.category+page+element.username}-2`}>
                            <Table.Td>{t('auctionPrice')}:<br/><br/> {element.price.toFixed(0)} TON<br/><br/>{t('approxPrice')}:<br/><br/> {element.scored_price} $</Table.Td>
                            <Table.Td>
                              {t('relevanceScore')}
                              <br/>
                              <br/>
                              <Badge color={element.relevance_score < 30 ? "var(--hint_color_hover)" : element.relevance_score >= 60 ? "green" : "var(--color_orange)"} size="lg">
                                {element.relevance_score}%
                              </Badge>
                              <br/>
                              <br/>
                              {t('popularityScore')}
                              <br/>
                              <br/>
                              <Badge color={element.popularity_score < 30 ? "var(--hint_color_hover)" : element.popularity_score >= 60 ? "green" : "var(--color_orange)"} size="lg">
                                {element.popularity_score}%
                              </Badge>
                            </Table.Td>
                          </Table.Tr>
                        </Table.Tbody>
                      </Table>
                    </Table.Td>

                    <Table.Td className='show-desktop color-accent' onClick={(e) => openUsernameOnPlatform(e, `https://fragment.com/username/${element.username}`)}>
                      <a target="_blank" rel="noreferrer" href={`https://fragment.com/username/${element.username}`}>
                        @{element.username}
                      </a>
                    </Table.Td>
                    <Table.Td className='show-desktop'>{element.price.toFixed(0)} TON</Table.Td>
                    <Table.Td className='show-desktop'>{element.scored_price} $</Table.Td>
                    <Table.Td className='show-desktop'>
                      <Badge color={element.relevance_score < 30 ? "var(--destructive_text_color)" : element.relevance_score >= 60 ? "green" : "var(--color_orange)"} size="lg">
                        {element.relevance_score}%
                      </Badge>
                    </Table.Td>
                    <Table.Td className='show-desktop'>
                      <Badge color={element.popularity_score < 30 ? "var(--destructive_text_color)" : element.popularity_score >= 60 ? "green" : "var(--color_orange)"} size="lg">
                        {element.popularity_score}%
                      </Badge>
                    </Table.Td>

                    {/* <Table.Td>
                      <a target="_blank" rel="noreferrer" href={`https://fragment.com/username/${element.username}`}>
                        <button className="link fagment-button-md">Open Fragment.com</button>
                        <button className="link fagment-button-sm">Open</button>
                      </a>
                    </Table.Td> */}

                  </Table.Tr>
                ))}
              </Table.Tbody>
            )}
            { needBuy && <Table.Tbody>
              <Table.Tr>
                <Table.Td colSpan={6}>
                  <div onClick={() => setOverlayVisible(true)} style={{margin: '64px', textAlign: 'center', color: 'var(--destructive_text_color)'}}>
                    <h1>{t('buySubscription')}</h1>
                  </div>
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
            }
          </Table>
        </div>
        <div className="pagination">
          <div className="total-count">{t('totalCount', { count: categories[activeTab]?.count || 0 })}</div>
          <Pagination
            total={Math.ceil((categories[activeTab]?.count || 0) / 20)}
            siblings={1}
            defaultValue={page}
            withControls={false}
            nextIcon={IconArrowRight}
            previousIcon={IconArrowLeft}
            dotsIcon={IconGripHorizontal}
            autoContrast
            color="button_color"
            classNames={{ control: 'pagination-control' }}
            onChange={changePage}
            value={page}
          />
        </div>
      </div>
      <footer>@ 2024 Usernames Сenter</footer>
      {isOverlayVisible && (
        <Overlay color="#000" backgroundOpacity={0.85}>
          <Stories
            width="100vw"
            height="100vh"
            renderers={[Welcome, FreeViewing, SubscriptionOptions]}
            stories={[{ type: 'Welcome' }, { type: 'FreeViewing' }, { type: 'SubscriptionOptions' }]}
            defaultInterval={1000}
            onAllStoriesEnd={() => setOverlayVisible(false)}
          />
        </Overlay>
      )}
    </div>
  );
};

export default MainPage;
