import React, { useContext } from 'react';
import { BookmarkContext } from '../contexts/BookmarkContext';
import { AuthContext } from '../contexts/AuthContext';
import Navbar from '../components/Navbar';
import { Table } from '@mantine/core';
import { useTranslation } from 'react-i18next';

const ProfilePage = () => {
  const { t } = useTranslation();
  const { bookmarks } = useContext(BookmarkContext);
  const { authUser } = useContext(AuthContext);

  if (!authUser) {
    return (
      <div>
        <Navbar />
        <div className="profile-page">
          <p>{t('pleaseLogin')}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar authUser={authUser} />
      <div className="profile-page">
        <h1>{t('profile', { username: authUser.username })}</h1>
        <h2>{t('bookmarkedUsernames')}</h2>
        {bookmarks.length === 0 ? (
          <p>{t('noBookmarks')}</p>
        ) : (
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{t('username')}</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {bookmarks.map((username) => (
                <Table.Tr key={username}>
                  <Table.Td>@{username}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
