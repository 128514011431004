import { telegramLogin } from '../services/api';

export const telegramBotName = "usernames_center_bot";
export const telegramBotId = 7357102896;

export const onTelegramAuth = (data) => {
    if (data.id) {
        window.localStorage.setItem('telegram_login_data', JSON.stringify(data));
        telegramLogin(data);
    }
}

export const telegramAuth = () => {
    window.Telegram.Login.auth({ bot_id: telegramBotId }, onTelegramAuth);
}
