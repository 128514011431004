import React from 'react';
import { useTranslation } from 'react-i18next';

const FreeViewingRenderer = ({ story, action, isPaused, config }) => {
    const { t } = useTranslation();
    return (
        <div className="story-component free-viewing">
            <h1>{t('exploreForFree')}</h1>
            <br/><br/>
            <h3><strong style={{color:'var(--color_orange)'}}>{t('pagesForFree')}</strong></h3>
            <br/><br/>
            <h2>{t('wantMore')}</h2>
            <br/>
            <h3 style={{color:'var(--color_orange)'}}>
                {t('unlockFullList')}
            </h3>
            <br/>
            <div style={{textAlign: "center"}}>
                <img width="65%" src="/diamond.gif" alt="Locked content" />
            </div>
        </div>
    );
};

export const renderer = FreeViewingRenderer;

export const tester = (story) => {
    return {
        condition: story.type === 'FreeViewing',
        priority: 1,
    };
};
