import React from 'react';

const SubscriptionTable = ({ handleSubscribe, action }) => {
  return (
    <table className='subscription-table' style={{ width: '100%', borderCollapse: 'collapse', fontWeight: 'bold' }}>
      <tbody>
        <tr>
          <td style={{ padding: '10px 0' }}>Day</td>
          <td style={{ padding: '10px 0', textAlign: 'right' }}>⭐</td>
          <td style={{ padding: '10px 0', textAlign: 'center' }}>350</td>
          <td style={{ padding: '10px 0', textAlign: 'left' }}>Stars</td>
          <td style={{ padding: '10px 0', textAlign: 'right', fontWeight: 'normal' }}>
            <button
              onClick={() => handleSubscribe('1day', action)}
              style={{ padding: '8px 16px', cursor: 'pointer' }}
            >
              Subscribe
            </button>
          </td>
        </tr>
        <tr>
          <td style={{ padding: '10px 0' }}>Week</td>
          <td style={{ padding: '10px 0', textAlign: 'right' }}>⭐</td>
          <td style={{ padding: '10px 0', textAlign: 'center' }}>1500</td>
          <td style={{ padding: '10px 0', textAlign: 'left' }}>Stars</td>
          <td style={{ padding: '10px 0', textAlign: 'right', fontWeight: 'normal' }}>
            <button
              onClick={() => handleSubscribe('1week', action)}
              style={{ padding: '8px 16px', cursor: 'pointer' }}
            >
              Subscribe
            </button>
          </td>
        </tr>
        <tr>
          <td style={{ padding: '10px 0' }}>Month</td>
          <td style={{ padding: '10px 0', textAlign: 'right' }}>⭐</td>
          <td style={{ padding: '10px 0', textAlign: 'center' }}>5000</td>
          <td style={{ padding: '10px 0', textAlign: 'left' }}>Stars</td>
          <td style={{ padding: '10px 0', textAlign: 'right', fontWeight: 'normal' }}>
            <button
              onClick={() => handleSubscribe('1month', action)}
              style={{ padding: '8px 16px', cursor: 'pointer' }}
            >
              Subscribe
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SubscriptionTable;
