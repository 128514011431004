import { useEffect, useState } from 'react';

export const useTelegramLoginData = () => {
    const [loginData, setLoginData] = useState(null);

    useEffect(() => {
        if (window?.localStorage) {
            try {
                const loginData =  JSON.parse(window.localStorage.getItem('telegram_login_data'));
                setLoginData(loginData);
            } catch (error) {
                // pass
            }
        }
    }, []);

    return loginData;
};
