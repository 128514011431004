import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation resources
const resources = {
  en: {
    translation: {
      "exploreUsernames": "Explore Usernames",
      "builtForTelegram": "Built for Telegram",
      "joinChannel": "Join Channel",
      "features": "Features",
      "exploreForFree": "Explore for Free!",
      "pagesForFree": "3 pages for any category of usernames available for free viewing",
      "wantMore": "Want more?",
      "unlockFullList": "Unlock the full list with our subscription!",
      "chooseSubscription": "Choose Your Subscription",
      "getFullAccess": "Get full access to our usernames database!",
      "limitedFree": "Limited FREE",
      "letsGetStarted": "Let’s get started!",
      "welcome": "Welcome to Usernames Center!",
      "analyzeUsernames": "We analyze all available Telegram usernames for purchase",
      "aiCategorizes": "Our AI categorizes them and provides an assessment of their relevance, estimated cost, and popularity rating!",
      "buySubscription": "Only first 3 pages are available for free viewing. Buy subscription for view more pages!",
      "totalCount": "Total count: {{count}} usernames",
      "pleaseLogin": "Please log in to view your profile.",
      "profile": "{{username}}'s Profile",
      "bookmarkedUsernames": "Bookmarked Usernames",
      "noBookmarks": "You have no bookmarks.",
      "searchPlaceholder": "Search",
      "username": "Username",
      "auctionPrice": "Fragment Price",
      "approxPrice": "Estimated Price",
      "relevanceScore": "Relevance Score",
      "popularityScore": "Popularity Score",
    }
  },
  ru: {
    translation: {
      "exploreUsernames": "Найди свое идеальное имя",
      "builtForTelegram": "Создано для Telegram",
      "joinChannel": "Наш канал",
      "features": "Преимущества",
      "exploreForFree": "Исследуйте бесплатно!",
      "pagesForFree": "3 страницы в любой категории доступны для бесплатного просмотра",
      "wantMore": "Хотите больше?",
      "unlockFullList": "Разблокируйте полный список с нашей подпиской!",
      "chooseSubscription": "Выберите свою подписку",
      "getFullAccess": "Получите полный доступ к нашей базе имен пользователей!",
      "limitedFree": "Продолжить БЕСПЛАТНО",
      "letsGetStarted": "Поехали!",
      "welcome": "Добро пожаловать в Telegram Usernames Center!",
      "analyzeUsernames": "Мы анализируем все доступные для покупки имена пользователей Telegram",
      "aiCategorizes": "Наш ИИ категоризирует их и предоставляет оценку их актуальности, предполагаемой стоимости и рейтинга популярности!",
      "buySubscription": "Только первые 3 страницы доступны для бесплатного просмотра. Купите подписку, чтобы получить полный доступ!",
      "totalCount": "Общее количество: {{count}} имен пользователей",
      "pleaseLogin": "Пожалуйста, войдите, чтобы просмотреть свой профиль.",
      "profile": "Профиль {{username}}",
      "bookmarkedUsernames": "Закладки имен пользователей",
      "noBookmarks": "У вас нет закладок.",
      "searchPlaceholder": "Поиск",
      "username": "Имя пользователя",
      "auctionPrice": "Цена на Fragment",
      "approxPrice": "Оценка стоимости",
      "relevanceScore": "Рейтинг релевантности",
      "popularityScore": "Рейтинг популярности",
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: (window.Telegram?.WebApp?.initDataUnsafe?.user?.language_code) || (navigator.language || navigator.userLanguage).split('-')[0], // Use Telegram language code if available
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // React already does escaping
    }
  });

export default i18n; 