import React, { createContext, useState, useEffect, useContext } from 'react';
import { fetchBookmarks, addBookmark, removeBookmark } from '../services/api';
import { AuthContext } from './AuthContext';

export const BookmarkContext = createContext();

export const BookmarkProvider = ({ children }) => {
  const { authUser } = useContext(AuthContext);
  const [bookmarks, setBookmarks] = useState([]);

  // useEffect(() => {
  //   if (authUser) {
  //     loadBookmarks();
  //   }
  // }, [authUser]);

  const loadBookmarks = async () => {
    try {
      const data = await fetchBookmarks(authUser.id);
      setBookmarks(data);
    } catch (error) {
      console.error('Error loading bookmarks:', error);
    }
  };

  const addToBookmarks = async (username) => {
    try {
      await addBookmark(authUser.id, username);
      setBookmarks((prev) => [...prev, username]);
    } catch (error) {
      console.error('Error adding bookmark:', error);
    }
  };

  const removeFromBookmarks = async (username) => {
    try {
      await removeBookmark(authUser.id, username);
      setBookmarks((prev) => prev.filter((item) => item !== username));
    } catch (error) {
      console.error('Error removing bookmark:', error);
    }
  };

  return (
    <BookmarkContext.Provider value={{ bookmarks, addToBookmarks, removeFromBookmarks }}>
      {children}
    </BookmarkContext.Provider>
  );
};
