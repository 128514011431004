import './i18n';
import '@mantine/core/styles.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import App from './App';

import * as Sentry from "@sentry/react";
import { AuthProvider } from './contexts/AuthContext';
import { BookmarkProvider } from './contexts/BookmarkContext';
import { BrowserRouter } from 'react-router-dom';

Sentry.init({
  dsn: "https://09cbff9678eccdfd5bb3869a431ba083@sentry.usernames.center/2",
  integrations: [
    Sentry.browserTracingIntegration,
    Sentry.replayIntegration,
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/api\.usernames\.center/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MantineProvider defaultColorScheme="dark">
    <AuthProvider>
      <BookmarkProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </BookmarkProvider>
    </AuthProvider>
  </MantineProvider>
);
