import React from 'react';
import { createInvoiceLink } from '../../services/api';
import SubscriptionTable from '../SubscriptionTable';
import { telegramAuth } from '../../utils/telegramAuth';
import { useTranslation } from 'react-i18next';

const handleSubscribe = async (type, action) => {
    const result = await createInvoiceLink(type, null);
    if (result.ok) {
        if (window.Telegram?.WebApp) {
            try {
                window.Telegram.WebApp.openInvoice(result.result);
            } catch (error) {
                window.open(result.result, '_blank');
            }
        } else {
            window.open(result.result, '_blank');
        }
    }
    window.ym(98682253, 'reachGoal', 'requestSubscribe', { type });
    return action();
};

const SubscriptionOptionsRenderer = ({ story, action, isPaused, config }) => {
    const { t } = useTranslation();
    return (
        <div className="story-component subscription-options">
            <h1>{t('chooseSubscription')}</h1>
            <br/>
            <h2 style={{color:'var(--color_orange)'}}>{t('getFullAccess')}</h2>
            <br/>
            { SubscriptionTable({ handleSubscribe, action }) }
            <br/>
            <div style={{textAlign: 'center'}}>
                <button onClick={action}>{t('limitedFree')}</button>
            </div>
            <br/>
            <h2 style={{textAlign: 'center'}} className='lets-started-h2'>{t('letsGetStarted')}</h2>
            <br/>
            <div style={{textAlign: "center"}}>
                <img width="65%" src="/rocket-launch.gif" alt="Rocket" />
            </div>
        </div>
    );
};

export const renderer = SubscriptionOptionsRenderer;

export const tester = (story) => {
    return {
        condition: story.type === 'SubscriptionOptions',
        priority: 1,
    };
};
